#main_page > button > div > img {
    width: 50px;
    position: relative;
    display: flex;
}

.button_share {
    flex-direction: column;
    display: flex;
    background: transparent;
    border-radius: 0;
    border: transparent;
    align-items: center;
}

.share_p {
    color: white;
    font-family: Montserrat;
    width: 70%;
    text-align: center;
}
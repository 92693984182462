#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#app img {
    position: absolute;
    z-index: 1;
    max-width: 55%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

html, body {
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    height: 100vh;
    background-color: #222228;
}

/*next*/

#main_page {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    gap: 20px;
    height: 100%;
}
#main_page > h1 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 48px */
    text-transform: uppercase;
    margin: 0;
    line-height: 105%;
}

#main_page > p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 20px */
    margin: 0;
}

orange {
    background-color: #FFFFFF;
    color: #E44E5E;
    padding: 0 10px;
    margin: 10px;
}

orange_timer {
    background-color: #FFFFFF;
    color: #E44E5E;
    padding: 5px 10px;
    font-size: 34px;
    font-weight: 500;
}

button.claim_btn {
    border: 0;
    display: inline-flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #FFFFFF;
    color: #E44E5E;
    font-family: Montserrat;
    font-weight: 700;
    margin: 0;
    font-size: 24px;
}

.box {
    cursor: pointer;
}

#app > img.pop-image {
    z-index: 4;
    width: 50%;
    background-color: rgba(34, 34, 40, 0.7);
}

/*animated background*/

#particles canvas {
    background: transparent !important;
}